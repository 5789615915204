import _move from "../lib/move";
import * as plansConstants from "@pcloud/web-utilities/dist/plans/constants.plans";
import { socialProofPlanInfo, getPrice } from "@pcloud/web-utilities/dist/plans/plansInfo";
import { currencyPrices } from "@pcloud/web-utilities/dist/plans/prices";

window.socialProofPlanInfo = socialProofPlanInfo;
window.getPrice = getPrice;
window.currencyPrices = currencyPrices;

const _attachList = [
  {
    attach: self,
    list: [
      //Plan IDS
      "BUSINESS_PLAN_ID",
      "BUSINESS_PLAN_2_ID",
      "BUSINESS_PLAN_API_ID",
      "BUSINESS_PLAN_2_API_ID",
      "BUSINESS_PLANS_ID_MAP",
      "PREMIUM_PLAN_ID",
      "PREMIUM_PLUS_PLAN_ID",
      "CUSTOM4TB_PLAN_ID",
      "CUSTOM_8COUNT_PLAN_ID",
      "FAMILY2TB_PLAN_ID",
      "CUSTOM_PLAN_ID",
      "ADD_STORAGE_1COUNT_PLAN_ID",
      "ADD_STORAGE_4COUNT_PLAN_ID",
      "CRYPTO_PLAN_ID",
      "EFH_PLAN_ID",
    ]
  }
]

_move(plansConstants, _attachList);